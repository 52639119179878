import { default as _91_46_46_46slug_93naX7YPwGSeMeta } from "/vercel/path0/apps/home/pages/[...slug].vue?macro=true";
import { default as detailsn3HYf5x1O1Meta } from "/vercel/path0/apps/home/pages/details.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/apps/home/pages/[...slug].vue")
  },
  {
    name: "details",
    path: "/details",
    meta: detailsn3HYf5x1O1Meta || {},
    component: () => import("/vercel/path0/apps/home/pages/details.vue")
  }
]