import revive_payload_client_75H8XfVLeg from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.1_ioredis@5_oogdwkfncumbysopzqdpvczk4i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_4yQC33Db9n from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.1_ioredis@5_oogdwkfncumbysopzqdpvczk4i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Y2pb9irGHg from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.1_ioredis@5_oogdwkfncumbysopzqdpvczk4i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_haft67PPvL from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.1_ioredis@5_oogdwkfncumbysopzqdpvczk4i/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_E21tovUFm5 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.1_ioredis@5_oogdwkfncumbysopzqdpvczk4i/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8TYupgwFuv from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.1_ioredis@5_oogdwkfncumbysopzqdpvczk4i/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ds0FnvO4oV from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.1_ioredis@5_oogdwkfncumbysopzqdpvczk4i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/home/.nuxt/components.plugin.mjs";
import prefetch_client_k4ac0vZ2mz from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.1_ioredis@5_oogdwkfncumbysopzqdpvczk4i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_bGQrZxgwrY from "/vercel/path0/node_modules/.pnpm/nuxt-viewport@2.2.0_magicast@0.2.11_rollup@4.24.4_vue@3.5.12_typescript@5.6.3_/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import documentDriven_CSxy4M6BNR from "/vercel/path0/node_modules/.pnpm/@nuxt+content@2.13.4_ioredis@5.4.1_magicast@0.3.5_nuxt@3.13.2_@parcel+watcher@2.5.0_@types+no_g6ya6ulo747lzytd2brbhtztve/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
export default [
  revive_payload_client_75H8XfVLeg,
  unhead_4yQC33Db9n,
  router_Y2pb9irGHg,
  payload_client_haft67PPvL,
  navigation_repaint_client_E21tovUFm5,
  check_outdated_build_client_8TYupgwFuv,
  chunk_reload_client_ds0FnvO4oV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_k4ac0vZ2mz,
  plugin_client_bGQrZxgwrY,
  documentDriven_CSxy4M6BNR
]